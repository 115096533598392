import { useEffect, useRef, useState } from "react";
import CustomModal from "../../../components/CustomModal";
import CustomButton from "../../../components/CustomButton";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../../utils/constants";
import { useSelector } from "react-redux";
import moment from "moment";

function OTPModal({ isOpen, onClose, redirectUrl }) {

  const [otp, setOtp] = useState(new Array(6).fill(''));
  const inputsRef = useRef([]);
  const navigate = useNavigate();
  const { userId, blockUntil } = useSelector((state) => state.authReducer);

  const [blockTimer, setBlockTimer] = useState('');
  const [blockUser, setBlockUser] = useState(false);
  const [localBlockUntil, setLocalBlockUntil] = useState(blockUntil);

  useEffect(() => {
    setLocalBlockUntil(blockUntil);
  }, [blockUntil]);

  useEffect(() => {
    if (!localBlockUntil) return;

    let intervalId;

    const calculateRemainingTime = () => {
      const now = moment();
      const unblockTime = moment(localBlockUntil);
      const diffDuration = moment.duration(unblockTime.diff(now));

      if (diffDuration.asMilliseconds() <= 0) {
        setBlockTimer('');
        setBlockUser(false);
        clearInterval(intervalId);
        return;
      }

      const minutes = Math.floor(diffDuration.asMinutes());
      const seconds = Math.floor(diffDuration.asSeconds()) % 60;

      setBlockUser(true);
      setBlockTimer(`${minutes} minutes ${seconds} seconds`);
    };

    calculateRemainingTime();

    intervalId = setInterval(calculateRemainingTime, 1000);

    return () => clearInterval(intervalId);
  }, [localBlockUntil]);


  function handleInputChange(event, index) {
    const { value } = event.target;
    if (/^[0-9]{1,2}$/.test(value)) {
      const newOtp = [...otp];
      value.split('').forEach((val, idx) => {
        if (index + idx < 6) {
          newOtp[index + idx] = val;
        }
      });
      setOtp(newOtp);
      if (index < 5) {
          inputsRef.current[index + 1].focus();
      }
    }
  }

  const handleBackspace = (e, index) => {
    if (e.key === 'Backspace') {
      if (otp[index]) {
          const newOtp = [...otp];
          newOtp[index] = '';
          setOtp(newOtp);
      } else if (index > 0) {
          inputsRef.current[index - 1].focus();
          const newOtp = [...otp];
          newOtp[index - 1] = '';
          setOtp(newOtp);
      }
    } 
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData('text').slice(0, 6).split('');
    const newOtp = [...otp];
    pasteData.forEach((char, index) => {
        if (/^[0-9]$/.test(char) && index < 6) {
            newOtp[index] = char;
        }
    });
    setOtp(newOtp);
    // Move focus to the last filled input
    const lastFilledIndex = pasteData.length - 1;
    if (lastFilledIndex < 6) {
        inputsRef.current[lastFilledIndex].focus();
    }
  };

  const handleSubmitOtp = () => {
    console.log("insid ehandle submit")
    const otpCode = otp.join('');
    if (otpCode.length !== 6) {
      toast('Invalid Code Entered', { type: 'warning' });
      return;
    }
    axios
      .post(`${baseUrl}/api/auth/verify-otp`, { otp: otpCode, userId })
      .then(res => res.data)
      .then(data => {
        if (data.success) {
          toast("Authenticated", { type: "success" });
          localStorage.setItem(
            "user-token",
            JSON.stringify(data.authToken)
          );
          navigate(redirectUrl ?? "/");
        } else if (data.status === "blocked") {
          setLocalBlockUntil(data.blockUntil);
        } else {
          toast(data.message ?? "Invalid OTP Entered", { type: 'error' });  
        }
      })
      .catch(err => {
        toast(err.response.data.message, { type: 'error' });
      });
  }

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      title={"Two Factor Authentication"}
      modalStyle={"min-w-96"}
    >
      <div className="grow flex flex-col flex-wrap content-center mt-12">
        { blockUser && <p className="text-red-700 text-justify border border-red-500 bg-red-100 p-3 rounded mb-5">
          Your account is locked due to unsuccessful tries. Please try after {blockTimer}.
        </p> }
        <p className="text-lg text-center">Enter the 6-digit code generated by your authenticator app</p>
        <div className="flex justify-center mt-2 mb-6 space-x-2">
          {otp.map((digit, index) => (
              <input
                  key={index}
                  type="text"
                  className="w-12 h-12 text-center text-xl border-2 border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                  value={digit}
                  onChange={(e) => handleInputChange(e, index)}
                  onKeyDown={(e) => handleBackspace(e, index)}
                  onPaste={handlePaste}
                  ref={(el) => (inputsRef.current[index] = el)}
              />
          ))}
        </div>
        
        <div className="flex justify-end mb-10">
          <CustomButton disabled={blockUser} buttonSize={'sm'} onClick={() => handleSubmitOtp()}>Submit</CustomButton>
        </div>
      </div>
    </CustomModal>
  )
}

export default OTPModal;
