import axios from 'axios';
import { store } from '../reducers/store'
import { setAuthData } from "../reducers/auth.reducer";

axios.interceptors.request.use((config) => {
  const token = JSON.parse(localStorage.getItem("user-token"));
  if (token) {
    config.headers['authorization'] = token;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

axios.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response && error.response.status === 401) {
    localStorage.removeItem("user-token")
    store.dispatch(setAuthData({}))
  } else {
    return Promise.reject(error);
  }
})
