/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { store } from "../../../reducers/store";
import CustomButton from "../../../components/CustomButton";
import NewOffenderModel from "./NewOffenderModal";
import { baseUrl } from "../../../utils/constants";
import { toast } from "react-toastify";

function OffenderPage() {
  const navigate = useNavigate();
  const [offenders, setOffenders] = useState([]);
  const [isOffendersFetched, setOffendersFetched] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const reducer = store.getState().authReducer;

  useEffect(() => {
    if(!isOffendersFetched) {
      loadOffendersData();
      setOffendersFetched(true)
    }
    // eslint-disable-next-line
  }, []);

  const loadOffendersData = () => {
    const userToken = reducer.userToken;
    axios
      .get(`${baseUrl}/api/user/get-offenders`, {
        headers: {
          "Content-Type": "application/json",
          authorization: userToken,
        },
      })
      .then((res) => {
        if (!res.data.success) {
          navigate("/");
          toast(res.data.message, { type: "error" });
        } else {
          setOffenders(res.data.offenders);
        }
      })
      .catch((err) => {
        console.error(err);
        toast("something went wrong", { type: "error" });
      });
  };

  const handleCreateNewOffender = (event, setUserDetails, userDetails) => {
    event.preventDefault();
    const loadingID = toast.loading("Please wait...");
    const userToken = localStorage.getItem("user-token");
    axios
      .post(`${baseUrl}/api/user/add-offender`, userDetails, {
        headers: {
          "Content-Type": "application/json",
          authorization: JSON.parse(userToken),
        },
      })
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        if (data.success) {
          toast.update(loadingID, {
            render: "Successfully added new offender",
            type: "success",
            isLoading: false,
            autoClose: 2000,
            closeOnClick: true,
          });
          closeModal();
          setUserDetails({});
        } else {
          toast.update(loadingID, {
            render: "something went wrong. try again later.",
            type: "error",
            isLoading: false,
            autoClose: 2000,
            closeOnClick: true,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        toast.update(loadingID, {
          render: "something went wrong",
          type: "error",
          isLoading: false,
          autoClose: 2000,
          closeOnClick: true,
        });
      });
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="w-full h-full px-5">
      <h1 className="text-xl mt-4 font-semibold text-left">Offenders</h1>

      <div className="max-w-md my-2">
        <CustomButton
          onClick={openModal}
          buttonSize="sm"
          buttonType="outline"
          buttonColor="primary"
          customStyle="text-md"
        >
          <span className="flex flex-row gap-2 items-center justify-center">
            Create New
          </span>
        </CustomButton>
      </div>

      <div className="mt-5 border border-stone-400 rounded-lg">
        <table className="table-auto w-full text-black text-md">
          <thead className="font-bold border-b">
            <tr>
              <td className="p-4 text-right">S. No.</td>
              <td className="p-4">First Name</td>
              <td className="p-4">Last Name</td>
              <td className="p-4">Email</td>
            </tr>
          </thead>
          <tbody className="">
            {offenders &&
              offenders.map((offender, idx) => (
                <tr
                  key={idx}
                  className="hover:text-primary hover:border-b hover:rounded-lg hover:border-stone-200"
                >
                  <td className="p-4 text-right">{idx + 1}</td>
                  <td className="p-4">{offender.firstName}</td>
                  <td className="p-4">{offender.lastName}</td>
                  <td className="p-4">{offender.email}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <NewOffenderModel
        isOpen={isModalOpen}
        closeModal={closeModal}
        handleCreateNewOffender={handleCreateNewOffender}
      />
    </div>
  );
}

export default OffenderPage;
