import React, { useState, useEffect } from "react";
import {
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
  Switch,
  Field,
  Label,
} from "@headlessui/react";
import axios from "axios";
import { baseUrl } from "../utils/constants";
import CustomModal from "./CustomModal";
import CustomButton from "./CustomButton";
import { store } from "../reducers/store";
import CheckmarkIcon from "../sources/icons/CheckmarkIcon.svg";
import UserIcon from "../sources/icons/UserIcon.svg";
import { toast } from "react-toastify";

const ProfileModal = ({ isOpen, closeModal }) => {
  const [userDetails, setUserDetails] = useState({
    imageUrl: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    userName: "",
    conversationCount: "",
    role: "",
  });

  const [formValues, setFormValues] = useState({
    imageUrl: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    userName: "",
    conversationCount: "",
    role: "",
  });

  const [notifyPrefs, setNotifyPrefs] = useState({ notifyEmail: false, notifySMS: false });
  const [notifyPrefsTemp, setNotifyPrefsTemp] = useState({ notifyEmail: false, notifySMS: false });

  const [isEdited, setIsEdited] = useState(false);
  const [areNotifyPrefsUpdated, setAreNotifyPrefsUpdated] = useState(false);

  useEffect(() => {
    if (isOpen) {
      const userId = store.getState().authReducer.userId;
      const userToken = JSON.parse(localStorage.getItem("user-token"));
      axios
        .get(`${baseUrl}/api/user/user-profile/${userId}`, {
          headers: {
            authorization: userToken,
          },
        })
        .then((res) => {
          return res.data.data;
        })
        .then(({imageUrl, firstName, lastName, email, phoneNumber, userName, conversationCount, role, notifyEmail, notifyTextMessage}) => {
          setUserDetails((prevState) => ({ ...prevState, imageUrl, firstName, lastName, email, phoneNumber, userName, conversationCount, role }));
          setFormValues((prevState) => ({ ...prevState, imageUrl, firstName, lastName, email, phoneNumber, userName, conversationCount, role }));
          
          setNotifyPrefs((prevState) => ({ ...prevState, notifyEmail, notifySMS: notifyTextMessage }));
          setNotifyPrefsTemp((prevState) => ({ ...prevState, notifyEmail, notifySMS: notifyTextMessage }));
        });
    }
    // .then(data => console.log(data));
  }, [isOpen]);

  useEffect(() => {
    setIsEdited(!(JSON.stringify(formValues) === JSON.stringify(userDetails)));
  }, [formValues, userDetails]);
  
  useEffect(() => {
    setAreNotifyPrefsUpdated(!(JSON.stringify(notifyPrefs) === JSON.stringify(notifyPrefsTemp)));
  }, [notifyPrefs, notifyPrefsTemp]);

  const handleSave = async (e) => {
    e.preventDefault();
    if (isEdited) {
      console.log("upload the data");
      const userToken = JSON.parse(localStorage.getItem("user-token"));
      axios
        .post(`${baseUrl}/api/user/edit-profile`, formValues, {
          headers: {
            authorization: userToken,
          },
        })
        .then((res) => {
          toast("Updated Successfully!", { type: "success" });
          setIsEdited(!isEdited);
          setUserDetails(formValues);
        });
    }
  };

  const handleSaveNotifyPrefs = (e) => {
    axios
      .post(
        `${baseUrl}/api/user/notification-preferences`,
        notifyPrefsTemp,
        {
          headers: {
            authorization: JSON.parse(localStorage.getItem("user-token")),
          },
        }
      )
      .then((res) => {
        setNotifyPrefs(notifyPrefsTemp);
      });
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const updateNotificationPreferences = (key, value) => {
    setNotifyPrefsTemp((prevState) => ({ ...prevState, [key]: value }));
  };

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={() => {
        setIsEdited(false);
        closeModal();
      }}
      titleStyle={"w-full flex items-center justify-left"}
      modalStyle={"w-full md:w-3/4 lg:w-1/2 h-2/3 md:h-1/2"}
      title=""
    >
      <div className="flex flex-col grow w-full h-full">
        <TabGroup className="flex flex-col grow h-full w-full absolute left-0 top-0">
          <div className="flex grow flex-row rounded-xl w-full">
            <div className="min-w-1/6 p-4 min-h-full bg-slate-100 border-r-2">
                <p className="text-md md:text-xl text-black mb-5 pb-3 border-b-2">
                  Account Settings
                </p>
              <TabList className="flex flex-col text-gray-800 pr-3 size-full gap-2">
                <Tab as={React.Fragment}>
                  {({ selected }) => (
                    <button
                      className={`block w-full text-md md:text-xl text-left px-4 py-2 rounded hover:bg-gray-200 ${
                        selected ? "bg-gray-300" : ""
                      }`}
                    >
                      Profile
                    </button>
                  )}
                </Tab>
                <Tab as={React.Fragment}>
                  {({ selected }) => (
                    <button
                      className={`block w-full text-md md:text-xl text-left px-4 py-2 rounded hover:bg-gray-200 ${
                        selected ? "bg-gray-300" : ""
                      }`}
                    >
                      Preferences
                    </button>
                  )}
                </Tab>
              </TabList>
            </div>
            <div className="flex-1 p-4 w-full">
              <TabPanels as="section" className="h-full w-full">
                <TabPanel className="h-full">
                  <div className="flex flex-row w-full h-full rounded-md border-slate-200 py-2">
                    <form className="w-full flex flex-col justify-between">
                      <div>
                        <div className="flex justify-center items-center mb-4 flex-col gap-2">
                          <div className="flex justify-center items-center h-24 w-24 rounded-full bg-[#6B7DBB]">
                            <img
                              src={UserIcon}
                              alt=""
                              className="h-16 w-16 rounded-full bg-[#6B7DBB]"
                            />
                          </div>
                          <div>
                            @{formValues.userName}{" "}
                            {formValues.role === "supervisor"
                              ? "(officer)"
                              : ""}
                          </div>
                        </div>
                        <div className="flex flex-row flex-wrap gap-4 md:gap-0 mx-2 mb-2">
                          <div className="w-full md:w-1/3 px-3">
                            <input
                              className="w-full border rounded-md p-3"
                              type="text"
                              name="firstName"
                              value={
                                formValues.firstName ? formValues.firstName : ""
                              }
                              onChange={handleInputChange}
                              placeholder="First Name"
                            />
                          </div>
                          <div className="w-full md:w-1/3 px-3">
                            <input
                              className="w-full border rounded-md p-3"
                              type="text"
                              name="middleName"
                              value={
                                formValues.middleName
                                  ? formValues.middleName
                                  : ""
                              }
                              onChange={handleInputChange}
                              placeholder="Middle Name"
                            />
                          </div>
                          <div className="w-full md:w-1/3 px-3">
                            <input
                              className="w-full border rounded-md p-3"
                              type="text"
                              name="lastName"
                              value={
                                formValues.lastName ? formValues.lastName : ""
                              }
                              onChange={handleInputChange}
                              placeholder="Last Name"
                            />
                          </div>
                        </div>
                        <div className="flex flex-row flex-wrap mx-2 mb-2 mt-4 md:mt-0 gap-4 md:gap-0">
                          <div className="w-full md:w-1/2 px-3">
                            <input
                              className="w-full border rounded-md p-3"
                              type="email"
                              name="email"
                              value={formValues.email ? formValues.email : ""}
                              onChange={handleInputChange}
                              placeholder="Email"
                            />
                          </div>
                          <div className="w-full md:w-1/2 px-3">
                            <input
                              className="w-full border rounded-md p-3"
                              type="phone"
                              name="phoneNumber"
                              value={
                                formValues.phoneNumber
                                  ? formValues.phoneNumber
                                  : ""
                              }
                              onChange={handleInputChange}
                              placeholder="Phone Number"
                            />
                          </div>
                        </div>
                      </div>

                      <div>
                        <div className="flex flex-wrap justify-end mx-2 px-3">
                          <CustomButton
                            onClick={handleSave}
                            buttonSize="md"
                            buttonType="solid"
                            buttonColor="primary"
                            customStyle=""
                            disabled={!isEdited}
                          >
                            <span className="flex flex-row gap-2 items-center justify-center">
                              Save
                              <img
                                src={CheckmarkIcon}
                                alt="Search Icon"
                                height={20}
                                width={20}
                                className="mb-1.5"
                              />
                            </span>
                          </CustomButton>
                        </div>
                      </div>
                    </form>
                  </div>
                </TabPanel>

                <TabPanel className="w-full flex h-full flex-col justify-between">
                  <div>
                    <div className="flex flex-col w-full h-full rounded-md p-2">
                      <Field className="flex gap-2 items-center">
                        <Switch
                          checked={notifyPrefsTemp.notifyEmail}
                          onChange={(x) => {
                            updateNotificationPreferences("notifyEmail", x);
                          }}
                          className="group inline-flex h-6 w-11 items-center rounded-full bg-gray-200 transition data-[checked]:bg-blue-600"
                        >
                          <span className="size-4 translate-x-1 rounded-full bg-white transition group-data-[checked]:translate-x-6" />
                        </Switch>
                        <Label className="pt-1">Email notifications</Label>
                      </Field>

                      <br />

                      <Field className="flex gap-2 items-center">
                        <Switch
                          checked={notifyPrefsTemp.notifySMS}
                          onChange={(x) => {
                            updateNotificationPreferences("notifySMS", x);
                          }}
                          className="group inline-flex h-6 w-11 items-center rounded-full bg-gray-200 transition data-[checked]:bg-blue-600"
                        >
                          <span className="size-4 translate-x-1 rounded-full bg-white transition group-data-[checked]:translate-x-6" />
                        </Switch>
                        <Label className="pt-1">Text notifications</Label>
                      </Field>
                    </div>
                  </div>
                  <div>
                    <div className="flex flex-wrap justify-end mx-2 px-3">
                      <CustomButton
                        onClick={handleSaveNotifyPrefs}
                        buttonSize="md"
                        buttonType="solid"
                        buttonColor="primary"
                        customStyle=""
                        disabled={!areNotifyPrefsUpdated}
                      >
                        <span className="flex flex-row gap-2 items-center justify-center">
                          <span>Save</span>
                          <img
                            src={CheckmarkIcon}
                            alt="Search Icon"
                            height={20}
                            width={20}
                            className=""
                          />
                        </span>
                      </CustomButton>
                    </div>
                  </div>
                </TabPanel>
              </TabPanels>
            </div>
          </div>
        </TabGroup>
      </div>
    </CustomModal>
  );
};

export default ProfileModal;
