import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import LoginPage from "../pages/login/LoginPage";
import { setAuthData } from "../reducers/auth.reducer";
import { store } from "../reducers/store";
import { fetchUserInfo } from "../utils/fetchUserInfo";
import Lottie from "react-lottie";
import { useSelector } from "react-redux";
import LoadingAnimation from "../sources/lotties/loadingEnvelope.json";

const ProtectedRoute = (props) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const { userId } = useSelector((state) => state.authReducer);
  
  const checkUserToken = async () => {
    let userToken = localStorage.getItem("user-token");
    setTimeout(() => {
      if (!userToken || userToken === "undefined") {
        setIsLoggedIn(false);
        setLoading(false);
        return navigate("/login", { state: { from: location } });
      } else {
        if (!userId) {
          userToken = JSON.parse(userToken);
          fetchUserInfo(userToken).then((data) => {
            if (data.success) {
              store.dispatch(setAuthData(data.data));
              setIsLoggedIn(true);
              setLoading(false);
            } else {
              setIsLoggedIn(false);
              setLoading(false);
            }
          });
        } else {
          setLoading(false);
          setIsLoggedIn(true);
        }
      }
    }, 1000);
  };

  useEffect(() => {
    checkUserToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadingAnimation = () => {
    return (
      <div className="w-screen h-screen flex items-center justify-center flex-col">
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: LoadingAnimation,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
          width={300}
          height={300}
        />
        <p>Loading...</p>
      </div>
    );
  };

  const loadPage = () => {
    return isLoggedIn ? props.children : <LoginPage />;
  };
  return <>{loading ? loadingAnimation() : loadPage()}</>;
};

export default ProtectedRoute;
