import React, { useState, useEffect } from "react";
import html2canvas from "html2canvas";
import CustomButton from "../../components/CustomButton";
import { store } from "../../reducers/store";
import BehaviorChain from "../../sources/images/BehaviorChain1.png";
import ODRCBehaviorChain from "../../sources/images/BehaviorChain.png";
import { saveToolForm } from "../../utils/saveToolForm";

const emotions = [
  { src: `/images/emotions/aggressive.png`, name: "Aggressive" },
  { src: `/images/emotions/angry.png`, name: "Angry" },
  { src: `/images/emotions/bored.png`, name: "Bored" },
  { src: `/images/emotions/cautious.png`, name: "Cautious" },
  { src: `/images/emotions/confident.png`, name: "Confident" },
  { src: `/images/emotions/confused.png`, name: "Confused" },
  { src: `/images/emotions/determined.png`, name: "Determined" },
  { src: `/images/emotions/disappointed.png`, name: "Disappointed" },
  { src: `/images/emotions/disapproving.png`, name: "Disapproving" },
  { src: `/images/emotions/enraged.png`, name: "Enraged" },
  { src: `/images/emotions/exhausted.png`, name: "Exhausted" },
  { src: `/images/emotions/frightened.png`, name: "Frightened" },
  { src: `/images/emotions/frustrated.png`, name: "Frustrated" },
  { src: `/images/emotions/grieving.png`, name: "grieving" },
  { src: `/images/emotions/guilty.png`, name: "Guilty" },
  { src: `/images/emotions/happy.png`, name: "Happy" },
  { src: `/images/emotions/hurt.png`, name: "Hurt" },
  { src: `/images/emotions/lonely.png`, name: "Lonely" },
  { src: `/images/emotions/miserable.png`, name: "Miserable" },
  { src: `/images/emotions/negative.png`, name: "Negative" },
  { src: `/images/emotions/nervous.png`, name: "Nervous" },
  { src: `/images/emotions/optimistic.png`, name: "Optimistic" },
  { src: `/images/emotions/overwhelmed.png`, name: "Overwhelmed" },
  { src: `/images/emotions/relieved.png`, name: "Relivied" },
  { src: `/images/emotions/resentful.png`, name: "Resentful" },
  { src: `/images/emotions/sad.png`, name: "Sad" },
  { src: `/images/emotions/satisfied.png`, name: "Satisfied" },
  { src: `/images/emotions/scared.png`, name: "Scared" },
  { src: `/images/emotions/sorry.png`, name: "Sorry" },
  { src: `/images/emotions/surprised.png`, name: "Surprised" },
  { src: `/images/emotions/suspicious.png`, name: "Suspicious" },
  { src: `/images/emotions/undecided.png`, name: "Undecided" },
  { src: `/images/emotions/withdrawn.png`, name: "Withdrawn" },
  { src: `/images/emotions/anxious.png`, name: "Anxious" },
];

const BehaviorChainForm = ({ imageText, setShowImage, closeModal }) => {
  const authReducer = store.getState().authReducer;
  const currentDate = new Date();
  const shouldRenderContent =
    imageText.situation &&
    imageText.positiveConsequences &&
    imageText.negativeConsequences &&
    imageText.actions &&
    imageText.thoughts &&
    imageText.newActions &&
    imageText.newPositiveConsequences &&
    imageText.newThoughts &&
    imageText.newNegativeConsequences;
  const formattedDate = currentDate.toLocaleDateString();
  const [downloadedImage, setDownloadedImage] = useState(null);

  useEffect(() => {
    generatePNG();

    // eslint-disable-next-line
  }, []);

  const adjustFontSize = (text, elementId) => {
    if (typeof text !== "string") {
      text = String(text);
    }
    const wordCount = text.trim().length;
    const element = document.getElementById(elementId);
    if (element) {
      if (wordCount > 200) {
        element.style.fontSize = "11px";
      } else {
        element.style.fontSize = "12px";
      }
    }
  };

  const generatePNG = async () => {
    try {
      const container = document.createElement("div");
      container.style.visibility = "hidden";
      container.style.position = "absolute";
      container.style.width = "1024px";
      container.style.height = "768px";
      document.body.appendChild(container);

      const feelings = imageText.feelings
        ?.split(",")
        .map((v) => {
          const emotion = emotions.find((e) => e.name === v.trim());
          if (!emotion) {
            return `<div className="flex flex-col items-center justify-center">
              <img
                class="h-12 w-12"
                alt={${v}}
                src="/images/emotions/emotionless.png"
              ></img>
              <div>${v}</div>
            </div>`;
          }
          return `<img
              alt="${emotion.name}"
              src="${emotion.src}"
              class="h-12 w-12"
            ></img>`;
        })
        .join("");

      const newFeelings = imageText.newFeelings
        ?.split(",")
        .map((v) => {
          const emotion = emotions.find((e) => e.name === v.trim());
          if (!emotion) {
            return `<div className="flex flex-col items-center justify-center">
              <img
                class="h-12 w-12"
                alt={${v}}
                src="/images/emotions/emotionless.png"
              ></img>
              <div>${v}</div>
            </div>`;
          }
          return `<img
              alt="${emotion.name}"
              src="${emotion.src}"
              class="h-12 w-12"
            ></img>`;
        })
        .join("");

      container.innerHTML = `
      <div class="">
      <img
        src="${ODRCBehaviorChain}" 
        alt="Behavior Chain"
        class="w-full h-full object-contain"
      /> 
      <p class="p-1 text-sm absolute w-[20.5rem] top-7 left-24 h-8"> ${authReducer?.firstName} ${authReducer?.lastName}</p>
      <p class="p-1 text-sm absolute w-[12.5rem] top-7 right-0 mr-10 h-8">${formattedDate}</p>
      <p id="situation" class="p-1 text-[12px] absolute w-[27.5rem] top-20 mt-1 left-10 h-16 overflow-y-hidden">${imageText.situation}</p>
      <p id="thoughts" class=" p-1 text-[12px] absolute max-w-[24.5rem] top-48 left-10 h-48 overflow-y-hidden"> ${imageText.thoughts} </p>
      <p class="absolute top-96 mt-4 left-52 font-bold text-sm "> New Thoughts </p>
      <p id="newthoughts" class=" p-1 text-[12px] absolute max-w-[24.5rem] top-96 mt-4 left-10 h-48 overflow-y-hidden">  <br> ${imageText.newThoughts}</p>
      <p id="actions" class=" p-1 text-[12px] absolute w-[24.5rem] top-96 mt-60 left-10 overflow-y-hidden"> ${imageText.actions} </p> 
      <p class="absolute top-96 mt-72 left-52 font-bold text-sm "> New Actions </p>
      <p id="newActions" class=" p-1 text-[12px] absolute w-[24.5rem] top-96 mt-72 left-10 overflow-y-hidden"><br> ${imageText.newActions} </p> 
      <div id="feelings" class="flex flex-wrap flex-row gap-4 p-2 text-[12px] absolute w-[22rem] max-w-[22rem] top-24 right-0 mr-6 h-48">
      ${feelings}
      <div class="text-md w-full text-center">New Feelings</div>
      <div class="w-full flex flex-row flex-wrap gap-4">
      ${newFeelings}
      </div>
      </div>
      <p id="positive" class=" p-1 text-[12px] absolute w-[22rem] max-w-[19rem] top-80 mt-7 right-0 mr-8 h-24 overflow-y-hidden text-start">${imageText.positiveConsequences}</p>
      <p class="absolute top-96 mt-16 right-0 mr-24 font-bold text-sm "> New Positive Consequences </p>
      <p id="newPositive" class=" p-1 text-[12px] absolute w-[22rem] max-w-[19rem] top-96 mt-16 right-0 mr-8 h-20 overflow-y-hidden ">  <br>${imageText.newPositiveConsequences}</p>
      <p id="negative" class=" p-1 text-[12px] absolute w-[22rem] max-w-[19rem] top-96 mt-40 right-0 mr-8 h-20 overflow-y-hidden "> ${imageText.negativeConsequences}</p>
      <p class="absolute top-96 mt-60 right-0 mr-24 font-bold text-sm "> New Negative Consequences </p>
      <p id="newNegative" class=" p-1 text-[12px] absolute w-[22rem] max-w-[19rem] top-96 mt-64 right-0 mr-8 h-24 overflow-y-hidden"> <br>${imageText.newNegativeConsequences}</p>
      </div>
  `;

      adjustFontSize(imageText.thoughts, "thoughts");
      adjustFontSize(imageText.newThoughts, "newthoughts");
      adjustFontSize(imageText.positiveConsequences, "positive");
      adjustFontSize(imageText.newPositiveConsequences, "newPositive");
      adjustFontSize(imageText.negativeConsequences, "negative");
      adjustFontSize(imageText.newNegativeConsequences, "newNegative");
      adjustFontSize(imageText.situation, "situation");

      container.style.visibility = "visible";

      const canvas = await html2canvas(container, {
        width: 1024,
        height: 768,
      });

      const imageData = canvas.toDataURL("image/png");

      setDownloadedImage(imageData);
      document.body.removeChild(container);
      
      if (shouldRenderContent && imageText.newPositiveConsequences) {
        // Convert base64 to Blob
        const blob = await fetch(imageData).then((res) => res.blob());
        saveToolForm(blob, "behavior-chain");
      }
    } catch (error) {
      console.error("Error generating PNG:", error);
    }
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = downloadedImage;
    link.download = "behavior_chain_image.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handlePrint = () => {
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    document.body.appendChild(iframe);

    iframe.onload = function () {
      iframe.contentWindow.print();
    };

    const printDocument =
      iframe.contentDocument || iframe.contentWindow.document;
    printDocument.open();
    printDocument.write(
      `<img src="${downloadedImage}" style="max-width: 100%; height: auto;"  />`
    );
    printDocument.close();
  };

  const renderButtons = () => {
    if (shouldRenderContent) {
      return (
        <div className=" bg-white px-4 rounded-lg h-3/4 min-w-full ">
          <div className="relative bg-white  py-2 z-50 flex justify-center space-x-4 ">
            <CustomButton onClick={handleDownload} buttonColor="primary">
              Download
            </CustomButton>
            <CustomButton onClick={handlePrint} buttonColor="primary">
              Print
            </CustomButton>
            <CustomButton
              buttonColor={"danger"}
              buttonType={"outline"}
              buttonSize={"sm"}
              onClick={() => {
                setShowImage(false);
                setTimeout(() => {
                  window.botpressWebChat.sendEvent({
                    type: "proactive-trigger",
                    channel: "web",
                    payload: { text: "BehaviorChain Form displayed!" },
                  });
                }, 500);
                closeModal();
              }}
              customStyle={"mr-2"}
            >
              Close
            </CustomButton>
          </div>
          <div className=" border-t-2 border-dashed border-gray-300 mt-2 pt-4 "></div>
        </div>
      );
    } else {
      return (
        <div className=" px-4 rounded-lg h-3/4 min-w-full ">
          <div className="relative py-2 z-50 flex justify-end space-x-4 ">
            <CustomButton
              buttonColor={"danger"}
              buttonType={"solid"}
              buttonSize={"sm"}
              onClick={() => {
                setShowImage(false);
                setTimeout(() => {
                  window.botpressWebChat.sendEvent({
                    type: "proactive-trigger",
                    channel: "web",
                    payload: { text: "BehaviorChain Form displayed!" },
                  });
                }, 500);
                closeModal();
              }}
              customStyle={"mr-2"}
            >
              Close
            </CustomButton>
          </div>
          <div className=" border-t-2 border-dashed border-gray-300 mt-2 pt-4 "></div>
        </div>
      );
    }
  };

  return (
    <div className="absolute w-screen h-screen top-0 left-0 z-20 flex items-center justify-center">
      <div
        className={`p-2 rounded-lg flex items-center justify-center 2xl:w-2/4 lg:w-3/4 sm:w-4/5 w-5/6 md:w-4/5 z-50 ${
          shouldRenderContent ? "bg-white" : ""
        }`}
      >
        <div className={`flex flex-col w-full h-full rounded-lg`}>
          {renderButtons()}{" "}
          <div className="flex  justify-center">
            {shouldRenderContent && (
              <div
                className={`relative w-1/3 justify-self-start top-0  sm:left-4 ${
                  shouldRenderContent ? "text-black" : "text-blue-100"
                }`}
              >
                <div id="Name">
                  <p className=" flex text-[7px] sm:text-[10px] lg:text-[14px] ml-1 lg:mb-8 top-1">
                    Name:
                    <span className=" ml-2 underline">
                      {authReducer?.firstName} {authReducer?.lastName}
                    </span>
                  </p>
                </div>
              </div>
            )}
            <div
              id="behavior"
              className={`relative w-1/3 ${
                shouldRenderContent ? "text-black" : "text-blue-100 "
              }`}
            >
              <h2 className=" text-[10px] md:text-[1rem] lg:text-[21px] ml-4 text-center font-bold font-serif non-italic">
                BEHAVIOR CHAIN
              </h2>
            </div>
            {shouldRenderContent && (
              <div
                id="date"
                className={`relative w-2/6 ${
                  shouldRenderContent ? "text-black" : "text-blue-100"
                }`}
              >
                <p className="sm:text-[10px] text-[7px] lg:text-[14px] text-center ml-4 md:ml-16">
                  Date: <span className="ml-2 underline">{formattedDate}</span>
                </p>
              </div>
            )}
          </div>
          <div
            id="modal-container"
            className="relative w-full justify-self-start h-full flex rounded-lg"
          >
            <div
              className={`w-full h-full flex items-center justify-center absolute invisible sm:visible`}
            >
              <img src={BehaviorChain} className="h-72 w-[60rem]" alt="Behavior Chain" />
            </div>
            <div className="flex flex-col  bg-red- w-3/4">
              {imageText.situation && (
                <div
                  id="situation"
                  className={`relative py-3 mt-2 print:relative bg-red- right-0   ${
                    shouldRenderContent ? "text-black" : "text-blue-100"
                  }`}
                >
                  <h3 className="md:text-[8px] lg:text-[12px] xl:text-[14px] text-[7px] font-semibold justify-items-center p-1 ml-12 sm:lg:ml-24 md:ml-20">
                    Situation{" "}
                  </h3>
                  <p className="md:text-[8px] lg:text-[10px] xl:text-[12px] text-[7px] text-black bg-blue-50  min-h-[5vh] w-3/4 rounded-md px-3 py-2 ml-2  overflow-y-hidden ">
                    <span className="py-4">{imageText.situation} </span>
                  </p>
                </div>
              )}
              {imageText.thoughts && (
                <div
                  className={`relative  ${
                    shouldRenderContent ? "text-black" : "text-blue-100"
                  }`}
                >
                  <div id="head">
                    <h3 className="relative md:text-[8px] lg:text-[12px] xl:text-[14px] text-[7px] md:ml-20 font-semibold ml-12 xl:ml-24 lg:ml-24 py-1 mt-1">
                      Thoughts
                    </h3>
                  </div>
                  <div id="thought">
                    <p className="relative md:text-[0.55rem] lg:text-[10px] xl:text-[12px] text-[7px] text-black bg-blue-50 min-h-[12vh]  rounded-t-lg w-3/4 px-2 py-2 overflow-y-hidden ml-2">
                      {imageText.thoughts}
                    </p>
                    {imageText.newThoughts && (
                      <>
                        <div
                          className={` bg-blue-50 rounded-b-lg w-3/4 min-h-[12vh]  ml-2 px-2 py-2  overflow-y-hidden text-black`}
                        >
                          <div className=" border-t-1 border-dashed border-gray-300 pt-1">
                            {" "}
                          </div>
                          <p className=" relative md:text-[8px] lg:text-[10px] xl:text-[12px] text-[7px] font-semibold py-1 sm:ml-8  sm:lg:ml-16 ">
                            New Thoughts{" "}
                          </p>
                          <span className="md:text-[8px] lg:text-[10px] xl:text-[12px] text-[7px] overflow-hidden">
                            {" "}
                            {imageText.newThoughts}
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
              {imageText.actions && (
                <div
                  className={`relative    ${
                    shouldRenderContent ? "text-black" : "text-blue-100"
                  }`}
                >
                  <h3 className=" md:text-[8px] lg:text-[12px] xl:text-[14px] text-[7px] font-semibold ml-12 mt-2 md:ml-24 lg:py-2 ">
                    Actions
                  </h3>
                  <p className=" md:text-[0.55rem]  lg:text-[9.5px] xl:text-[12px] text-[7px] text-black rounded-lg bg-blue-50  px-2 py-2 min-h-[10vh] overflow-y-hidden w-3/4 ml-2 ">
                    {imageText.actions}
                    {imageText.newActions && (
                      <>
                        <div className=" border-t-1 border-dashed border-gray-300 pt-1">
                          {" "}
                        </div>
                        <p className=" md:text-[8px] lg:text-[10px] xl:text-[12px] text-[7px]  relative font-semibold py-1 ml-8 sm:lg:ml-16">
                          New Action{" "}
                        </p>
                        <span className="md:text-[0.55rem] lg:text-[9.5px] xl:text-[12px] text-[7px] py-1">
                          {" "}
                          {imageText.newActions}{" "}
                        </span>
                      </>
                    )}
                  </p>
                </div>
              )}
            </div>
            <div className="flex flex-col items-end w-3/5 relative top-0">
              {imageText.feelings && (
                <div id="feelings" className="relative w-3/4">
                  <div
                    className={`relative top-0 max-w-1/2  ${
                      shouldRenderContent ? "text-black" : "text-blue-100"
                    }`}
                  >
                    <h3 className=" md:text-[8px] lg:text-[12px] xl:text-[14px] text-[7px] font-semibold ml-6 sm:ml-14 py-2 ">
                      Feelings
                    </h3>
                    <div
                      className={`md:text-[8px] flex flex-row flex-wrap gap-4 lg:text-[9.5px] xl:text-[12px] text-[7px] text-black rounded-t-lg bg-blue-50 w-full mr-2 min-h-[5vh] p-2 overflow-y-hidden ${
                        imageText.feelings
                          ? "text-black bg-blue-50"
                          : "text-zinc-500 bg-transparent"
                      }`}
                    >
                      {imageText.feelings?.split(",").map((v) => {
                        const emotion = emotions.find(
                          (e) => e.name === v.trim()
                        );
                        if (!emotion) {
                          return (
                            <div className="flex flex-col items-center justify-center">
                              <img
                                height={35}
                                width={35}
                                alt={v}
                                src="/images/emotions/emotionless.png"
                              ></img>
                              <div>{v}</div>
                            </div>
                          );
                        }
                        return (
                          <img
                            height={35}
                            width={35}
                            alt={emotion.name}
                            src={emotion.src}
                          ></img>
                        );
                      })}
                    </div>
                    {imageText.newFeelings && (
                      <>
                        <div className=" md:text-[8px] lg:text-[10px] xl:text-[12px] text-[7px] text-black bg-blue-50 w-full mr-2 min-h-[5vh] rounded-b-lg  overflow-hidden py-2 px-2">
                          <div className=" border-t-1 border-dashed border-gray-300 pt-1">
                            {" "}
                          </div>
                          <p className=" relative font-semibold py-1  sm:ml-16 md:ml-10  ">
                            New Feelings{" "}
                          </p>{" "}
                          <div className="flex flex-wrap flex-row gap-4">
                            {imageText.newFeelings?.split(",").map((v) => {
                              const emotion = emotions.find(
                                (e) => e.name === v.trim()
                              );
                              if (!emotion) {
                                return (
                                  <div className="flex flex-col items-center justify-center">
                                    <img
                                      height={35}
                                      width={35}
                                      alt={v.name}
                                      src="/images/emotions/emotionless.png"
                                    ></img>
                                    <div>{v}</div>
                                  </div>
                                );
                              }
                              return (
                                <img
                                  height={35}
                                  width={35}
                                  alt={emotion.name}
                                  src={emotion.src}
                                ></img>
                              );
                            })}
                          </div>
                        </div>
                      </>
                    )}
                    <div className="absolute mr-8"></div>
                  </div>
                </div>
              )}
              {imageText.positiveConsequences && (
                <div id="consequences" className="relative w-3/4">
                  <div
                    className={`relative  ${
                      shouldRenderContent ? "text-black" : "text-blue-100"
                    }`}
                  >
                    <h3 className=" md:text-[8px] ml-6 sm:ml-14 lg:text-[12px] xl:text-[14px] text-[7px] font-semibold  py-2">
                      Consequences
                    </h3>
                    <p className=" md:text-[8px] lg:text-[9.5px] xl:text-[12px] text-[7px] text-black bg-blue-50  w-full mr-2 min-h-[12vh]   rounded-t-lg overflow-hidden p-2">
                      <h2 className="ml-10 md:ml-20 text-md">+</h2>
                      {imageText.positiveConsequences}
                    </p>
                    {imageText.newPositiveConsequences && (
                      <>
                        <div className=" md:text-[8px] lg:text-[10px] xl:text-[12px] text-[7px] text-black bg-blue-50 w-full mr-2 min-h-[12vh]  rounded-b-lg  overflow-hidden py-2 px-2">
                          <div className=" border-t-1 border-dashed border-gray-300 pt-1">
                            {" "}
                          </div>
                          <p className=" relative font-semibold py-1  sm:ml-16 md:ml-10  ">
                            New Consequences{" "}
                          </p>
                          <span className=" md:text-[8px] lg:text-[9px] text-[7px] xl:text-[12px]  py-1  ">
                            {" "}
                            {imageText.newPositiveConsequences}
                          </span>
                        </div>
                      </>
                    )}

                    {imageText.negativeConsequences && (
                      <p className=" md:text-[8px] lg:text-[9.5px] xl:text-[12px] text-[7px] text-black bg-blue-50 min-h-[12vh] w-full mt-2 mr-2 rounded-t-lg  overflow-hidden  p-2 ">
                        <p className="ml-10 sm:ml-20 text-md">-</p>
                        {imageText.negativeConsequences}
                      </p>
                    )}
                    {imageText.newNegativeConsequences && (
                      <>
                        <div className=" md:text-[8px] lg:text-[10px] xl:text-[12px] text-[7px] text-black bg-blue-50 w-full min-h-[12vh] mr-2  rounded-b-lg  overflow-hidden  p-2">
                          <div className=" border-t-1 border-dashed border-gray-300 pt-1">
                            {" "}
                          </div>
                          <p className=" relative font-semibold py-1 sm:ml-16 md:ml-10">
                            New Consequences{" "}
                          </p>
                          <span className=" md:text-[8px] xl:text-[12px] lg:text-[9.5px] text-[7px] py-1">
                            {" "}
                            {imageText.newNegativeConsequences}
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="absolute w-screen h-screen z-30 bg-zinc-800 opacity-80"></div>
    </div>
  );
};

export default BehaviorChainForm;
