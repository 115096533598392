import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { baseUrl } from "../../utils/constants";
import { toast } from "react-toastify";
import CustomButton from "../../components/CustomButton";
import CustomModal from "../../components/CustomModal";

import MicrosoftAuthenticator from "../../sources/icons/MicrosoftAuthenticator.svg"
import GoogleAuthenticator from "../../sources/icons/GoogleAuthenticator.svg"

function RegisterOTP() {
  const [searchParams] = useSearchParams();

  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);

  const navigate = useNavigate();

  const [otp, setOtp] = useState(new Array(6).fill(''));
  const inputsRef = useRef([]);

  useEffect(() => {
    const userId = searchParams.get("userId");
    axios
      .get(`${baseUrl}/api/auth/register-otp/${userId}`)
      .then(res => res.data)
      .then(data => {
        if (data.success) {
          setQrCodeUrl(data.qrCodeUrl);
        } else {
          toast(data.message, { type: 'error' });
        }
      })
      .catch(err => {
        toast(err.response.data.message, { type: 'error' });
      });
    inputsRef.current[0].focus();
  }, [searchParams]);

  function handleInputChange(event, index) {
    const { value } = event.target;
    if (/^[0-9]{1,2}$/.test(value)) {
      const newOtp = [...otp];
      value.split('').forEach((val, idx) => {
        if (index + idx < 6) {
          newOtp[index + idx] = val;
        }
      });
      setOtp(newOtp);
      if (index < 5) {
          inputsRef.current[index + 1].focus();
      }
    }
  }

  const handleBackspace = (e, index) => {
    if (e.key === 'Backspace') {
      if (otp[index]) {
          const newOtp = [...otp];
          newOtp[index] = '';
          setOtp(newOtp);
      } else if (index > 0) {
          inputsRef.current[index - 1].focus();
          const newOtp = [...otp];
          newOtp[index - 1] = '';
          setOtp(newOtp);
      }
    } 
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData('text').slice(0, 6).split('');
    const newOtp = [...otp];
    pasteData.forEach((char, index) => {
        if (/^[0-9]$/.test(char) && index < 6) {
            newOtp[index] = char;
        }
    });
    setOtp(newOtp);
    // Move focus to the last filled input
    const lastFilledIndex = pasteData.length - 1;
    if (lastFilledIndex < 6) {
        inputsRef.current[lastFilledIndex].focus();
    }
  };

  function redirectToAppStore(app) {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    let url = '';
    
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      if (app === 'google') {
        url = 'https://apps.apple.com/us/app/google-authenticator/id388497605';
      } else if (app === 'microsoft') {
        url = 'https://apps.apple.com/us/app/microsoft-authenticator/id983156458';
      }
    } else {
      if (app === 'google') {
        url = 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2';
      } else if (app === 'microsoft') {
        url = 'https://play.google.com/store/apps/details?id=com.azure.authenticator';
      }
    }

    window.open(url, '_blank');
  }

  const handleSubmit = () => {
    const otpCode = otp.join('');
    if (otpCode.length !== 6) {
      toast('Invalid Code Entered', { type: 'warning' });
      return;
    }
    const redirectUrl = searchParams.get("redirectUrl");
    axios
      .post(`${baseUrl}/api/auth/verify-otp`, { otp: otpCode, userId: searchParams.get("userId") })
      .then(res => res.data)
      .then(data => {
        if (data.success) {
          toast("Verified", { type: "success" });
          localStorage.setItem("user-token", JSON.stringify(data.authToken))
          navigate(redirectUrl === 'null' ? "/" : redirectUrl);
        } else {
          toast(data.message ?? "Invalid OTP received.", { type: 'error' });  
        }
      })
      .catch(err => {
        toast(err.response.data.message, { type: 'error' });
      });
  };

  return (
    <div className="flex flex-col flex-wrap min-h-screen justify-center content-center px-5">
      <div className="text-2xl text-center font-bold min-w-full mt-32">Setup Two Factor Authentication</div>
      <div className="grow flex flex-col flex-wrap content-center">
        <div className="text-xl text-center font-normal mt-12">You will need an Authenticator app to complete the process</div>
        <div className="flex justify-center">
          <p className="text-sm underline hover:cursor-pointer hover:text-blue-500" onClick={() => setShowSuggestions(true)}>Click here for suggestions</p>
          <CustomModal
            isOpen={showSuggestions}
            onClose={() => setShowSuggestions(false)}
            modalStyle="min-w-96"
          >
            <div>
              <div className="flex flex-col">
                <p className="text-lg font-semibold">Mobile Apps</p>
                <hr />
                <div className="flex flex-wrap justify-left content-center gap-3 mt-5 mb-3" onClick={() => redirectToAppStore('microsoft')}>
                  <img className="h-6 w-6 justify-self-start hover:cursor-pointer" src={MicrosoftAuthenticator} alt="" />
                  <p className="text-sm justify-self-end self-center underline">Microsoft Authenticator</p>
                </div>
                <div className="flex flex-wrap justify-left content-center gap-3 mb-3" onClick={() => redirectToAppStore('google')}>
                  <img className="h-6 w-6 hover:cursor-pointer"  src={GoogleAuthenticator} alt="" />
                  <p className="text-sm self-center underline">Google Authenticator</p>
                </div>
              </div>
              
              <div className="flex flex-col mt-2">
                <p className="text-lg font-semibold">Browser Extension</p>
                <hr />  
                <a
                  href="https://chromewebstore.google.com/detail/authenticator/bhghoamapcdpbohphigoooaddinpkbai?hl=en"
                  target="_blank" rel="noreferrer"
                  className="border border-black rounded-full px-2 py-1 max-w-fit mt-3"
                >
                  <button className="text-sm underline">Authenticator</button>
                </a>
              </div>

            </div>
          </CustomModal>
        </div>
        <p className="text-md text-center font-semibold mt-10">Scan the QR code into your app</p>
        <div className="flex justify-center">
          <img className="h-48 w-48" src={qrCodeUrl} alt="" />
        </div>
        <hr className="my-6" />
        <p className="text-lg text-center">Enter the 6-digit authentication code generated by your app</p>
        <div className="flex justify-center mt-2 mb-6 space-x-2">
          {otp.map((digit, index) => (
              <input
                  key={index}
                  type="text"
                  className="w-12 h-12 text-center text-xl border-2 border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                  value={digit}
                  onChange={(e) => handleInputChange(e, index)}
                  onKeyDown={(e) => handleBackspace(e, index)}
                  onPaste={(e) => handlePaste(e)}
                  ref={(el) => (inputsRef.current[index] = el)}
              />
          ))}
        </div>
        <div className="flex justify-end mb-10">
          <CustomButton buttonSize={'sm'} onClick={() => handleSubmit()}>Submit</CustomButton>
        </div>
      </div>
    </div>
  )
}

export default RegisterOTP;
