import React, { Fragment, useState, useEffect, useRef, useImperativeHandle, forwardRef } from "react";
import { Transition, Menu } from "@headlessui/react";
import MoreIcon from "../../../sources/icons/EllipsisVertical.svg";
import ShareIcon from "../../../sources/icons/ShareIcon.svg";
import ChevronDown from "../../../sources/icons/ChevronDown.js";
import InformationCardIcon from "../../../sources/icons/InformationCard.svg";
import DownloadIcon from "../../../sources/icons/downloadIcon.svg";
import UserIcon from "../../../sources/icons/UserIcon.svg";
import { store } from "../../../reducers/store";
import EditIcon from "../../../sources/icons/EditIcon.svg";
import EditModal from "../../../providers/FlowProvider/EditConversations";
import { useNavigate } from "react-router-dom";
import { setAuthData } from "../../../reducers/auth.reducer";
import CustomButton from "../../../components/CustomButton";
import axios from "axios";
import { baseUrl } from "../../../utils/constants";
import AttachmentsModal from "./AttachmentsModal.jsx";
import VideoThumbnail from "../../../components/VideoThumbnail.jsx";
import ProfileModal from "../../../components/ProfileModal.jsx";
import { BottomSheet } from "react-spring-bottom-sheet";
import 'react-spring-bottom-sheet/dist/style.css'
import MultipleMessageBubble from "../../../sources/icons/MultipleMessageBubble.js";
import LogoutIcon from "../../../sources/icons/LogoutIcon.js";
import { isMobile } from "../../../utils/isMobile.js";

const ConversationBox = forwardRef(({ currentConversationDetails, isConversationLoaded, openModal }, ref) => {
  const navigate = useNavigate();
  const authReducer = store.getState().authReducer;
  const [conversationDetails, setConversationDetails] = useState({});
  const [attachments, setAttachments] = useState([]);
  const [isAttachmentsModalOpen, setIsAttachmentsModalOpen] = useState(false);
  const [bottomSheet, setBottomSheet] = useState(null);
  const onLogOut = () => {
    localStorage.removeItem("user-token");
    store.dispatch(
      setAuthData({
        userId: null,
        userToken: null,
        userName: null,
        firstName: null,
        lastName: null,
        email: null,
        userRole: null,
      })
    );
    const chatBot = document.getElementById("bp-web-widget");
    if (chatBot) {
      chatBot.remove();
    }
    localStorage.clear();
    navigate("/login");
  };

  const editModalRef = useRef();

  const fetchConversationEditForm = async (conversationId) => {
    try {
      const response = await axios.get(
        baseUrl + "/api/conversation/edit-tool-form",
        {
          headers: {
            "Content-Type": "application/json",
            authorization: authReducer.userToken,
          },
          params: {
            conversationId,
          },
        }
      );

      if (response.data.success) {
        const conversationData = response.data.data.imageUrls;
        const form = response.data.data.toolName;
        editModalRef.current.open(form, conversationData);
      } else {
        console.error("Failed to fetch conversation data");
      }
    } catch (error) {
      console.error("Error fetching conversation data:", error);
    }
  };

  const handleEditClick = () => {
    const conversationId = currentConversationDetails.conversationId;
    fetchConversationEditForm(conversationId);
  };

  useImperativeHandle(ref, () => ({
    handleEditClick, 
  }));

  const fetchAttachments = async () => {
    try {
      const conversationId = currentConversationDetails.conversationId;

      if (!conversationId) {
        console.log("Conversation ID is not available yet.");
        return;
      }
      const response = await axios.get(
        baseUrl + "/api/conversation/pull-attachments/" + conversationId,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: authReducer.userToken,
          },
        }
      );
      const mediaData = response.data.data || [];

      setAttachments(mediaData);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchConversationDetails = async () => {
    try {
      axios
        .get(
          baseUrl +
            `/api/conversation/fetch-details/${currentConversationDetails.conversationId}`,
          {
            headers: {
              "Content-Type": "appelication/json",
              authorization: authReducer.userToken,
            },
          }
        )
        .then((response) => response.data.data)
        .then((data) => {
          data.createdDate = data.createdDate
            ? new Date(data.createdDate).toDateString()
            : "";
          data.lastUsedDate = data.lastUsedDate
            ? new Date(data.lastUsedDate).toDateString()
            : "";
          setConversationDetails(data);
        })
        .catch((e) => {
          console.log("Error fetching conversation details: ", e);
        });
    } catch (error) {
      console.error("Error fetching conversation details: ", error);
    }
  };

  useEffect(() => {
    if (isConversationLoaded && currentConversationDetails.conversationId) {
      fetchAttachments();
      fetchConversationDetails();
    }
    // eslint-disable-next-line
  }, [isConversationLoaded, currentConversationDetails.conversationId]);

  const downloadConversation = async () => {
    const response = await axios.get(
      baseUrl +
        "/api/conversation/download-conversation/" +
        currentConversationDetails.conversationId,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: authReducer.userToken,
        },
      }
    );

    const blob = new Blob([response.data.txt], { type: "text/plain" });
    const link = document.createElement("a");

    link.href = window.URL.createObjectURL(blob);
    link.download = response.data.name;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const openUserProfile = () => {
    setIsProfileOpen(true);
  };
  const closeUserProfile = () => {
    setIsProfileOpen(false);
  };
  const openDashboard = () => {
    navigate("/dashboard");
  };

  return (
    <div className="w-full h-full flex flex-row">
      <div
        id="conversation-box"
        className="flex flex-col items-center justify-start w-full lg:w-3/4 relative"
      >
        {isConversationLoaded ? (
          <div className="w-3/4 py-2 z-10 absolute rounded-lg mt-4 bg-[#F9FAFC] flex-row flex justify-between items-center px-6 shadow-lg">
            <div className="flex flex-col items-start">
              <h3 className="text-lg font-semibold">
                {currentConversationDetails.conversationTitle}
              </h3>
              <p className="text-sm font-light flex flex-row items-center justify-center">
                <span
                  className={`h-2 w-2 rounded-full ${
                    currentConversationDetails.isLocked
                      ? "bg-green-500"
                      : "bg-yellow-500"
                  } mb-0.5 mr-1`}
                ></span>
                {currentConversationDetails.isLocked
                  ? "Completed"
                  : "In Progress"}
              </p>
            </div>

            <div className="flex flex-row items-center justify-center gap-2">
              <div className="flex items-center justify-center bg-gray-100 p-1 rounded-full">
                <img src={ShareIcon} alt="Share Icon" height={16} width={16} />
              </div>
              {currentConversationDetails.isLocked && (
                <div className="flex items-center justify-center bg-gray-100 p-1 rounded-full">
                  <button onClick={handleEditClick} className="flex">
                    <img
                      src={EditIcon}
                      alt="Edit Icon"
                      height={20}
                      width={20}
                    />
                  </button>
                </div>
              )}
              <div className="flex items-center justify-center bg-gray-100 p-1 rounded-full">
                <Menu as="div" className="relative inline-block text-left">
                  <Menu.Button
                    onClick={(e) => {
                      if (!isMobile.any()) return;
                      setBottomSheet("toolDetails");
                      e.preventDefault();
                    }}
                    className="flex"
                  >
                    <img
                      src={MoreIcon}
                      alt="More Icon"
                      height={20}
                      width={20}
                    />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 overflow-y-scroll max-h-44 mt-2 w-56 origin-top-right divide-y divide-gray-200 px-2 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={downloadConversation}
                            className={`${
                              active
                                ? "bg-[#6B7DBB] text-white"
                                : "text-gray-900"
                            } group flex w-full items-center rounded-md px-2 py-2 text-sm justify-center`}
                          >
                            <img
                              src={DownloadIcon}
                              alt="Download Icon"
                              height={16}
                              width={16}
                              className={`${
                                active ? "text-white" : "rgb(17, 24, 39)"
                              } mb-1 mr-2`}
                            />
                            Download Tool
                          </button>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
        ) : (
          <div className="w-full h-full flex items-center justify-center bg-[#F1F2F9] flex-col">
            <div className="text-primary font-bold text-2xl mb-8">
              Let's create your first tool!
            </div>
            <CustomButton
              buttonColor={"primary"}
              buttonSize={"md"}
              onClick={() => openModal()}
              customStyle="py-3 px-4 text-xl"
            >
              New Tool
            </CustomButton>
          </div>
        )}

        <div
          className={`${
            isConversationLoaded ? "h-full" : "h-0"
          } w-full bg-gray-300 relative`}
          id="conversation-chat-container"
        ></div>
      </div>

      <BottomSheet
        open={bottomSheet === "toolDetails"}
        defaultSnap={({ maxHeight }) => maxHeight / 2}
        snapPoints={({ maxHeight }) => [
          maxHeight - maxHeight / 10,
          maxHeight / 4,
          maxHeight * 0.6,
        ]}
        header={
          <div className="flex flex-row justify-between items-center">
            <div className="flex flex-row gap-2 w-1/3"></div>
            <div className="text-2xl flex my-1 gap-4 flex-row w-full items-center justify-center">
              <img
                src={InformationCardIcon}
                alt="Search Icon"
                height={40}
                width={40}
              />
              <div className="h-full flex justify-center items-center mt-1">
                Tool Details
              </div>
            </div>
            <div className="w-1/3 bg-red-500"></div>
          </div>
        }
        footer={
          <div className="flex items-center justify-between">
            <div className="gap-2 flex flex-row items-left justify-center">
              <div className="flex flex-col items-center">
                <div
                  onClick={() => {
                    setBottomSheet(null);
                    onLogOut();
                  }}
                  className="p-3 w-fit rounded-lg bg-danger"
                >
                  <LogoutIcon height={25} width={25} color={"white"} />
                </div>
              </div>
              <div className="flex flex-col items-center">
                <div
                  onClick={() => {
                    setBottomSheet(null);
                    openDashboard();
                  }}
                  className="p-3 w-fit rounded-lg bg-[#6B7DBB]"
                >
                  <MultipleMessageBubble
                    color={"white"}
                    height={25}
                    width={25}
                  />
                </div>
              </div>
              <div className="flex flex-col items-center">
                <div
                  onClick={() => {
                    setBottomSheet(null);
                    setIsProfileOpen(true);
                  }}
                  className="p-3 w-fit rounded-lg bg-[#6B7DBB]"
                >
                  <img
                    src={UserIcon}
                    alt="User Icon"
                    height={25}
                    width={25}
                    color="#000"
                  />
                </div>
              </div>
            </div>
            <CustomButton
              buttonType={"solid"}
              buttonColor={"primary"}
              buttonSize={"lg"}
              customStyle="py-2 text-2xl"
              onClick={() => {
                setBottomSheet(null);
              }}
            >
              Done
            </CustomButton>
          </div>
        }
      >
        <div className="rounded-xl flex flex-col justify-end w-full px-2 overflow-scroll pb-20">
          <div className="w-full gap-3 flex flex-col mt-5">
            <div className="flex flex-row justify-between w-full">
              <div className="text-[#A7ADB7] text-lg">
                {authReducer.userRole === "offender" ? (
                  <div>
                    <p>
                      <strong>
                        <span className="text-[#0f172a]">Created On: </span>
                      </strong>
                      <br />
                      {conversationDetails.createdDate}
                    </p>{" "}
                    <br />
                    {conversationDetails.lastUsedDate && (
                      <React.Fragment>
                        <p>
                          <strong>
                            <span className="text-[#0f172a]">
                              Last Used On:{" "}
                            </span>
                          </strong>
                          <br />
                          {conversationDetails.lastUsedDate}
                        </p>
                        <br />
                      </React.Fragment>
                    )}
                    {conversationDetails.activeTool && (
                      <React.Fragment>
                        <p>
                          <strong>
                            <span className="text-[#0f172a]">
                              Active Tool:{" "}
                            </span>
                          </strong>
                          <br />
                          {conversationDetails.activeTool}
                        </p>{" "}
                        <br />
                      </React.Fragment>
                    )}
                  </div>
                ) : (
                  "DOB"
                )}
              </div>
            </div>
            {authReducer.userRole === "supervisor" && (
              <>
                <div className="flex flex-row justify-between w-full">
                  <div className="text-[#A7ADB7]">Gender</div>
                  <div>-</div>
                </div>
                <div className="flex flex-row justify-between w-full">
                  <div className="text-[#A7ADB7]">Race</div>
                  <div>-</div>
                </div>
                <div className="flex flex-row justify-between w-full">
                  <div className="text-[#A7ADB7]">Conversations</div>
                  <div>-</div>
                </div>
                <div className="flex flex-row justify-between w-full">
                  <div className="text-[#A7ADB7]">Progress</div>
                  <div>-</div>
                </div>
              </>
            )}
          </div>
          <div className="flex items-center w-full mt-4 justify-between">
            <div className="text-lg"> Attachments</div>
            {attachments.length > 0 && (
              <div
                onClick={() => {
                  setIsAttachmentsModalOpen(true);
                  setBottomSheet(null);
                }}
                className="text-lg text-[#6B7DBB] underline cursor-pointer"
              >
                View All
              </div>
            )}
          </div>
          <div className="mt-1">
            <div className="grid grid-cols-3 max-w-[410px] rounded-lg min-h-36 gap-1 bg-gray-200 p-1">
              {attachments.slice(0, 6).map((attachment, index) =>
                attachment.attachment.includes(".png") ||
                attachment.attachment.includes(".jpg") ||
                attachment.attachment.includes(".jpeg") ? (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={attachment.attachment}
                    key={index}
                    className="flex flex-col"
                  >
                    <div className="w-32 h-32 bg-white rounded-md flex overflow-hidden">
                      <img
                        src={attachment.attachment}
                        alt={attachment.name}
                        className="object-fill w-full h-[200%] cursor-pointer"
                      />
                    </div>
                  </a>
                ) : (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={attachment.attachment}
                    key={index}
                    className="flex flex-col"
                  >
                    <div className="w-32 h-32 bg-white rounded-md flex overflow-hidden">
                      <VideoThumbnail key={index} src={attachment.attachment} />
                    </div>
                  </a>
                )
              )}
            </div>
          </div>
        </div>
      </BottomSheet>
      <ProfileModal
        isOpen={isProfileOpen}
        openModal={openUserProfile}
        closeModal={closeUserProfile}
      />
      <div
        id="conversation-details-container"
        className="hidden lg:flex w-0 lg:w-1/4 bg-[#F1F2F9] h-full items-center justify-center relative"
      >
        <div className="absolute top-6 right-6 2xl:w-3/12 w-6/12 py-2 pr-2 bg-[#6B7DBB] shadow-md rounded-lg flex flex-row justify-start items-center z-10">
          <div className="w-12 h-12 absolute rounded-full bg-[#6B7DBB] -ml-3 flex items-center justify-center pb-1 shadow-md">
            <img
              src={UserIcon}
              alt="User Icon"
              height={24}
              width={24}
              color="#ffff"
            />
          </div>
          <div className="flex ml-9 items-center w-full justify-between text-white">
            <Menu as="div" className="relative w-full inline-block text-left">
              <div>
                <Menu.Button className="flex justify-between w-full items-center text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                  {authReducer?.userName.charAt(0).toUpperCase() +
                    authReducer?.userName.slice(1)}
                  <ChevronDown
                    alt="Menu Icon"
                    height={16}
                    width={16}
                    strokeColor="white"
                  />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 mt-4 w-56 origin-top-right divide-y divide-gray-200 px-2 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={openUserProfile}
                          className={`${
                            active ? "bg-[#6B7DBB] text-white" : "text-gray-900"
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        >
                          Account
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => openDashboard()}
                          className={`${
                            active ? "bg-[#6B7DBB] text-white" : "text-gray-900"
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        >
                          Dashboard
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => onLogOut()}
                          className={`${
                            active
                              ? "bg-red-500 text-white"
                              : "bg-[#6B7DBB] text-white"
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        >
                          Logout
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
        {isConversationLoaded && (
          <div className="flex flex-col 2xl:w-4/6 w-5/6 2xl:min-h-3/6 min-h-3/5 gap-4 ">
            <AttachmentsModal
              closeModal={() => setIsAttachmentsModalOpen(false)}
              isOpen={isAttachmentsModalOpen}
            />
            <div className=" bg-[#F9FAFC] max-h-full h-3/7 rounded-xl shadow-md relative flex justify-center">
              <div className="-top-10 absolute border-solid bg-white w-16 h-16 rounded-full flex items-center justify-center">
                <img
                  src={InformationCardIcon}
                  alt="Search Icon"
                  height={35}
                  width={35}
                />
              </div>
              <div className="py-8 px-6 items-center flex flex-col justify-start w-full">
                <h2>Tool Details</h2>
                <div className="w-full gap-3 flex flex-col mt-5">
                  <div className="flex flex-row justify-between w-full">
                    <div className="text-[#A7ADB7] text-sm">
                      {authReducer.userRole === "offender" ? (
                        <div>
                          <p>
                            <strong>
                              <span className="text-[#0f172a]">
                                Created On:{" "}
                              </span>
                            </strong>
                            <br />
                            {conversationDetails.createdDate}
                          </p>{" "}
                          <br />
                          {conversationDetails.lastUsedDate && (
                            <React.Fragment>
                              <p>
                                <strong>
                                  <span className="text-[#0f172a]">
                                    Last Used On:{" "}
                                  </span>
                                </strong>
                                <br />
                                {conversationDetails.lastUsedDate}
                              </p>
                              <br />
                            </React.Fragment>
                          )}
                          {conversationDetails.activeTool && (
                            <React.Fragment>
                              <p>
                                <strong>
                                  <span className="text-[#0f172a]">
                                    Active Tool:{" "}
                                  </span>
                                </strong>
                                <br />
                                {conversationDetails.activeTool}
                              </p>{" "}
                              <br />
                            </React.Fragment>
                          )}
                        </div>
                      ) : (
                        "DOB"
                      )}
                    </div>
                  </div>
                  {authReducer.userRole === "supervisor" && (
                    <>
                      <div className="flex flex-row justify-between w-full">
                        <div className="text-[#A7ADB7]">Gender</div>
                        <div>-</div>
                      </div>
                      <div className="flex flex-row justify-between w-full">
                        <div className="text-[#A7ADB7]">Race</div>
                        <div>-</div>
                      </div>
                      <div className="flex flex-row justify-between w-full">
                        <div className="text-[#A7ADB7]">Conversations</div>
                        <div>-</div>
                      </div>
                      <div className="flex flex-row justify-between w-full">
                        <div className="text-[#A7ADB7]">Progress</div>
                        <div>-</div>
                      </div>
                    </>
                  )}
                </div>
                <div className="rounded-xl flex flex-col justify-end w-full">
                  <div className="flex items-center w-full mt-4 justify-between">
                    <p> Attachments</p>
                    {attachments.length > 0 && (
                      <div
                        onClick={() => setIsAttachmentsModalOpen(true)}
                        className="text-sm text-[#6B7DBB] underline cursor-pointer"
                      >
                        View All
                      </div>
                    )}
                  </div>
                  <div className="mt-1">
                    <div className="grid grid-cols-3 overflow-hidden rounded-lg h-36 gap-1 bg-gray-200 p-1">
                      {attachments.slice(0, 6).map((attachment, index) =>
                        attachment.attachment.includes(".png") ||
                        attachment.attachment.includes(".jpg") ||
                        attachment.attachment.includes(".jpeg") ? (
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={attachment.attachment}
                            key={index}
                            className="flex flex-col"
                          >
                            <div className="w-16 h-16 bg-white rounded-md flex overflow-hidden">
                              <img
                                src={attachment.attachment}
                                alt={attachment.name}
                                className="object-fill w-full h-[200%] cursor-pointer"
                              />
                            </div>
                          </a>
                        ) : (
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={attachment.attachment}
                            key={index}
                            className="flex flex-col"
                          >
                            <div className="w-16 h-16 bg-white rounded-md flex overflow-hidden">
                              <VideoThumbnail
                                key={index}
                                src={attachment.attachment}
                              />
                            </div>
                          </a>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <EditModal ref={editModalRef} />
    </div>
  );
});

export default ConversationBox;
