import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { baseUrl } from "../../utils/constants";
import { useNavigate } from "react-router-dom";

function RegisterPage() {
  const [backendError, setBackendError] = useState(null);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();


  const onSubmit = (data) => {
    axios
      .post(baseUrl + "/api/auth/sign-up", {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        userName: data.userName,
        password: data.password,
      })
      .then(async function ({data}) {
        if (data.success) {
            localStorage.setItem('user-token', JSON.stringify(data.data.authToken))
            navigate(`/register-otp?userId=${data.data.userId}&redirectUrl=/`);
        } else {
            setBackendError(data.message)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    const userToken = localStorage.removeItem('user-token');
    if (userToken) {
      navigate("/")
    }
  })

  return (
    <div className="h-screen flex flex-col items-center justify-center bg-gray-800">
      <form
        className="2xl:w-1/5 md:w-2/5 w-4/5 bg-slate-600 py-10 flex flex-col items-center gap-3 rounded-lg"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="md:w-2/5 w-4/5 flex justify-center mb-5">
          <h1 className="text-3xl font-bold text-white">Sign Up</h1>
        </div>

        {errors.email && <span role="alert" className="text-red-400 text-md font-bold">{errors.email.message}</span>}
        {errors.password && <span role="alert" className="text-red-400 text-md font-bold -mt-3">{errors.password.message}</span>}
        {backendError && <span role="alert" className="text-red-400 text-md font-bold -mt-3">{backendError}</span>}
        <label className="flex flex-col w-8/12 text-white">
          First Name
          <input
            type="text"
            id="firstName"
            {...register("firstName", { required: true })}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:outline-none"
            placeholder="John"
            required
          />
        </label>
        <label className="flex flex-col w-8/12 text-white">
          Last Name
          <input
            type="text"
            id="lastName"
            {...register("lastName", { required: false })}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:outline-none"
            placeholder="Doe"
            required
          />
        </label>
        <label className="flex flex-col w-8/12 text-white">
          Email
          <input
            type="text"
            id="email"
            {...register("email", {
              required: true,
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Entered value does not match email format",
              },
            })}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:outline-none"
            placeholder="johndoe@kankabot.com"
            required
          />
        </label>
        <label className="flex flex-col w-8/12 text-white">
          Username
          <input
            type="text"
            id="userName"
            {...register("userName", { required: true })}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:outline-none"
            placeholder="John"
            required
          />
        </label>
        <label className="flex flex-col w-8/12 text-white">
          Password
          <input
            type="password"
            id="password"
            {...register("password", {
              required: true,
              minLength: {
                value: 5,
                message: "Minimum length for password is 5",
              },
            })}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:outline-none"
            placeholder="Password"
            required
          />
        </label>
        <input
          type="submit"
          value="Continue"
          className="bg-blue-500 hover:bg-blue-700 mt-5 text-white font-bold py-2 px-4 rounded"
        />
      </form>
    </div>
  );
}

export default RegisterPage;
