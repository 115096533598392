import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { ChatBubbleLeftRightIcon, ChevronDoubleRightIcon, HomeIcon, UserGroupIcon, UsersIcon, ChartPieIcon } from "@heroicons/react/24/outline";

import LogoutIcon from "../../../sources/icons/LogoutIcon.js";


import CustomButton from '../../../components/CustomButton';
import { store } from "../../../reducers/store";
import { setAuthData } from "../../../reducers/auth.reducer";

const Sidebar = () => {
  const navigate = useNavigate();
  
  const onLogOut = () => {
    localStorage.removeItem("user-token");
    store.dispatch(
      setAuthData({
        userId: null,
        userToken: null,
        userName: null,
        firstName: null,
        lastName: null,
        email: null,
        userRole: null,
      })
    );
    localStorage.clear();
    navigate("/login");
  };

  return (
    <aside className="flex flex-col justify-between w-60 h-full border-r-2 border-stone-300">
      <div>
        <h1 className="text-primary text-3xl font-semibold ml-4 mt-4 mb-20">
          Kanka Bot
        </h1>
        <nav className="flex justify-center">
          <ul className="text-base font-medium text-stone-500">
            <li>
              <NavLink
                className={({ isActive }) =>
                  `flex flex-row gap-2 mb-1 py-1 hover:text-black ${
                    isActive ? "text-black" : ""
                  }`
                }
                to="home"
              >
                <HomeIcon className="h-6 w-6" />
                <span className="pt-1">Home</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  `flex flex-row gap-2 mb-1 py-1 hover:text-black ${
                    isActive ? "text-black" : ""
                  }`
                }
                to="supervisor"
              >
                <UsersIcon className="h-6 w-6" />
                <span className="pt-1">Supervisors</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  `flex flex-row gap-2 mb-1 py-1 hover:text-black ${
                    isActive ? "text-black" : ""
                  }`
                }
                to="offender"
              >
                <UserGroupIcon className="h-6 w-6" />
                <span className="pt-1">Offenders</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  `flex flex-row gap-2 mb-1 py-1 hover:text-black ${
                    isActive ? "text-black" : ""
                  }`
                }
                to="analytics"
              >
                <ChartPieIcon className="h-6 w-6" />
                <span className="pt-1">Analytics</span>
              </NavLink>
            </li>
            <ul className="ml-8 text-sm font-normal list-inside">
              <li>
                <NavLink
                  className={({ isActive }) =>
                    `flex flex-row gap-2 mb-1 py-1 hover:text-black ${
                      isActive ? "text-black" : ""
                    }`
                  }
                  to="analytics/conversations"
                >
                  <ChatBubbleLeftRightIcon className="h-5 w-5" />
                  <span>Conversations</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    `flex flex-row gap-2 mb-1 py-1 hover:text-black ${
                      isActive ? "text-black" : ""
                    }`
                  }
                  to="analytics/conditions"
                >
                  <ChevronDoubleRightIcon className="h-5 w-5" />
                  <span>Conditions</span>
                </NavLink>
              </li>
            </ul>
          </ul>
        </nav>
      </div>
      <div className="flex justify-center p-1 mb-2">
        <CustomButton
          onClick={onLogOut}
          buttonSize="lg"
          buttonType="outline"
          buttonColor="danger"
          customStyle=""
        >
          <span className="flex flex-row gap-2 items-center justify-center">
            <LogoutIcon height={20} width={20} color={"white"}/>
            Logout
          </span>
        </CustomButton>
      </div>
    </aside>
  );
};

export default Sidebar;
