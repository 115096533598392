import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userId: null,
  userToken: null,
  userName: null,
  firstName: null,
  lastName: null,
  email: null,
  welcomingPageCompleted: false,
  userRole: null,
  blockUntil: null
};

const authReducer = createSlice({
  name: "authReducer",
  initialState,
  reducers: {
    setAuthData: (state, { payload }) => {
      state.userId = payload.userId;
      state.userToken = payload.authToken;
      state.userName = payload.userName;
      state.firstName = payload.firstName;
      state.lastName = payload.lastName;
      state.email = payload.email;
      state.userRole = payload.userRole;
      state.blockUntil = payload.blockUntil;
    },
    setWelcomePageStatus: (state, {payload}) => {
      state.welcomingPageCompleted = payload;
    }
  },
});

export const { setAuthData ,setWelcomePageStatus} = authReducer.actions;
export default authReducer.reducer;
